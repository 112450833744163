import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Layout/Header";
import MetaTags from "../components/Metatag/MetaTags";

const Notfound = () => {
  return (
    <>
    <MetaTags 
      title="404"
      description="Page not found"
      canonicalUrl="/*"
    />
    <Header />
    
      <section className="flex items-center h-full p-16 dark:bg-gray-50 dark:text-gray-800">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
          <div className="max-w-md text-center">
            <h2 className="mb-8 font-extrabold text-9xl dark:text-gray-400">
              <span className="sr-only">Error</span>404
            </h2>
            <p className="text-2xl font-semibold md:text-3xl">
              Sorry, we couldn't find this page.
            </p>
            <p className="mt-4 mb-4 dark:text-gray-600">
              But dont worry, you can find plenty of other things on our
              homepage.
            </p>
            <p className="mt-4 mb-8">If you have any questions or issues, please do not hesitate to <Link to="/contact-us" className="text-red-400 font-bold" >Contact Us</Link>.</p>
            <Link
              rel="noopener noreferrer"
              to="/"
              className="px-8 py-3 font-semibold rounded dark:bg-violet-600 dark:text-gray-50"
            >
              <button type="button" className="text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Back to Home</button>
            </Link>
          </div>
        </div>
      </section>

      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    </>
  );
};

export default Notfound;
